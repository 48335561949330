<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <h1>{{ storeshipping.stor_sh_nome }}</h1>
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'store-shippings'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Nome:</th>
                <td width="40%">{{ storeshipping.stor_sh_nome }}</td>
                <th width="10%">Nick:</th>
                <td width="40%">{{ storeshipping.stor_sh_nick }}</td>
            </tr>
            <tr>
                <th width="10%">Tipo:</th>
                <td width="90%" colspan="4">{{ storeshipping.stor_sh_tipo }}</td>
            </tr>
            <tr>
                <th width="10%">Mensagem:</th>
                <td width="90%" colspan="4">{{ storeshipping.stor_sh_mensagem }}</td>
            </tr>
            <tr>
                <th width="10%">Valor:</th>
                <td width="90%" colspan="4">{{ storeshipping.stor_sh_valor }}</td>
            </tr>
            <tr>
                <th width="10%">É Correios</th>
                <td width="40%" >{{ (storeshipping.stor_sh_is_correios == '1') ? 'SIM' : 'NÃO' }}</td>
                <th width="10%">CEP Origem</th>
                <td width="40%" >{{ storeshipping.stor_sh_cep_origem }}</td>
            </tr>
            <tr>
                <th width="10%">É Gratis</th>
                <td width="40%" >{{ (storeshipping.stor_sh_is_gratis == '1') ? 'SIM' : 'NÃO' }}</td>
                <th width="10%">Grátis maior que:</th>
                <td width="40%" >{{ storeshipping.stor_sh_gratis_maior_q }}</td>
            </tr>
            <tr>
                <th width="10%">Valor mínimo para Compra</th>
                <td width="40%" >{{ storeshipping.stor_sh_valor_minimo_compra }}</td>
                <th width="10%">Grátis maior que:</th>
                <td width="40%" >{{ storeshipping.stor_sh_gratis_maior_q }}</td>
            </tr>
            <tr>
                <th width="10%">Lista de Presentes</th>
                <td width="90%" colspan="4">{{ (storeshipping.stor_sh_is_lista_presente == '1') ? 'SIM' : 'NÃO' }}</td>
            </tr>
            <tr>
                <th width="10%">Status</th>
                <td width="90%" colspan="4">{{ (storeshipping.empl_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.loadStoreShipping()
    },
    name: "DetailStoreShippingComponent",
    props: {
        stor_sh_id: {
            require: true
        },
    },
    data() {
        return {
            search: '',
            storeshipping: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadStoreShipping() {
            this.$store.dispatch('loadStoreShipping', this.stor_sh_id)
                .then(response => {
                    this.storeshipping = response
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    }
};
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
